import { frontendURL } from '../../../../helper/URLHelper';

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: ['one_off', 'whatsapp_template_page'],
  menuItems: [
    // {
    //   icon: 'arrow-swap',
    //   label: 'ONGOING',
    //   key: 'ongoingCampaigns',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/campaigns/ongoing`),
    //   toStateName: 'ongoing_campaigns',
    // },
    {
      key: 'oneOffCampaigns',
      icon: 'sound-source',
      label: 'ONE_OFF',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
      toStateName: 'one_off',
    },
    {
      key: 'whatsappTemplateCampaigns',
      icon: 'sound-source',
      label: 'WHATSAPP_TEMPLATE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/whatsapp_template`),
      toStateName: 'whatsapp_template_page',
    },
  ],
});

export default campaigns;
