import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const SettingsChildContent = () => import('../WrapperChild.vue');
const Index = () => import('./Index.vue');
const WhatsAppTemplatesPage = () => import('./WhatsAppTemplatesPage');
const WhatsappTemplatesCreateRecord = () => import('./WhatsappTemplatesCreateRecord');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'arrow-swap',
      },
      children: [
        {
          path: '',
          redirect: 'one_off',
        },
        {
          path: 'ongoing',
          name: 'ongoing_campaigns',
          roles: ['administrator'],
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
        icon: 'sound-source',
      },
      children: [
        {
          path: 'one_off',
          name: 'one_off',
          roles: ['administrator'],
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.WHATSAPP_TEMPLATES.HEADER',
        icon: 'sound-source',
      },
      children: [
        {
          path: 'whatsapp_template',
          component: SettingsChildContent,
          children: [
            {
              path: '',
              name: 'whatsapp_template_page',
              roles: ['administrator'],
              component: WhatsAppTemplatesPage,
            },
            {
              path: ':inbox_id/add',
              name: 'whatsapp_template_create_page',
              roles: ['administrator'],
              component: WhatsappTemplatesCreateRecord,
              props: route => {
                return { inbox_id: parseInt(route.params.inbox_id), template_id: 0 };
              },
            },
            {
              path: ':inbox_id/edit/:template_id',
              name: 'whatsapp_template_edit_page',
              roles: ['administrator'],
              component: WhatsappTemplatesCreateRecord,
              props: route => {
                return { inbox_id: parseInt(route.params.inbox_id), template_id: route.params.template_id };
              },
            },
          ],
        },
      ],
    },
    
  ],
};
