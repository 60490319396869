/* global axios */
import ApiClient from './ApiClient';

class BotconfigAPI extends ApiClient {
  constructor() {
    super('botconfig', { accountScoped: true });
  }

  getSourceFiles(params) {
    return axios.get(this.url, { params });
  }
  getSourceLlm(params) {
    return axios.get(`${this.url}/llm`, { params });
  }

  create(params, options) {
    return axios.post(this.url, params, options);
  }
  createUrl(params, options) {
    console.log(params, 'create url');
    return axios.post(`${this.url}/create_url`, params, options);
  }
  addSourceSetting(params, options) {
    return axios.post(`${this.url}/add_source_setting`, params, options);
  }
  delete(params) {
    console.log(params, 'botconfigApi->deleteMethod');
    return axios.delete(`${this.url}/${params["file_id"]}`, {
      data: { hook_id: params["hook_id"] }
    });
  }
  deleteAll(params) {
    console.log(params, 'botconfigApi->delete_all');
    return axios.delete(`${this.url}/delete_all`, {
      data: { hook_id: params["hook_id"] }
    });
  }
  trainAll(params, options) {
    console.log(params, 'train all services url');
    return axios.post(`${this.url}/train_setting`, params, options);
  }
}

export default new BotconfigAPI();
