import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import BotconfigAPI from '../../api/botconfig';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    console.log("_state_state", _state)
    console.log("_state_state", _state.uiFlags)
    return _state.uiFlags;
  },
  getAttributes: _state => {
    return _state.records;
  },
  getSourceFiles: _state => {

    console.log("getSourceFilesgetSourceFiles", _state)
    return _state.records
    // .filter(
    //   record => record.attribute_model === attributeModel
    // );
  },
  getSourceLlm: _state => {

    console.log("getSource-llm", _state.records)
    return _state.records
    // .filter(
    //   record => record.attribute_model === attributeModel
    // );
  },
};

const changeData = (data) => {
  if(data.file) {
    const formData = new FormData();
    Object.keys(data).forEach(keyname => {
      console.log("keynamekeyname123", keyname, data[keyname])
      formData.append(`botconfig[${keyname}]`, data[keyname]);
    });
    console.log("formDataformData123", formData)
    return formData
  } else {
    return data
  }
}

export const actions = {
  get: async function getSourceFiles(paramss, params) {

    const { commit } = paramss
    console.log("paramsparamsparamsparams12322", paramss, params)


    const { hook_id } = params
    commit(types.SET_BOTCONFIG_UI_FLAG, { isFetching: true });
    try {
      const response = await BotconfigAPI.getSourceFiles({ hook_id: hook_id });
      console.log("paramsparamsparamsparams1234", response, response.data)

      commit(types.SET_BOTCONFIG, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isFetching: false });
    }
  },
  getLlm: async function getSourceLlm(paramss, params) {

    const { commit } = paramss
    console.log("paramsparamsparamsparams-llm12322", paramss, params)


    const { hook_id } = params
    commit(types.SET_BOTCONFIG_UI_FLAG, { isFetching: true });
    try {
      const response = await BotconfigAPI.getSourceLlm({ hook_id: hook_id });
      console.log("paramsparamsparamsparams-llm1234", response, response.data)

      commit(types.SET_BOTCONFIG, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createAttribute({ commit }, attributeObj) {
    commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: true });
    try {
      const payload = changeData(attributeObj)
      console.log("payloadpayloadpayload123", payload)
      var options = {}
      if(attributeObj.file) {
        options = { headers: { 'Content-Type': 'multipart/form-data' } }
      }
      const response = await BotconfigAPI.create(payload, options);
      commit(types.ADD_BOTCONFIG, response.data);
      return response.data
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: false });
    }
  },
  createUrl: async function createAttribute({ commit }, attributeObj) {
    commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: true });
    try {
      const payload = changeData(attributeObj)
      console.log("payloadpayloadpayloadCreateUrl", payload)
      var options = {}
      const response = await BotconfigAPI.createUrl(payload, options);
      commit(types.ADD_BOTCONFIG, response.data);
      return response.data
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_BOTCONFIG_UI_FLAG, { isUpdating: true });
    try {
      const response = await BotconfigAPI.update(id, updateObj);
      commit(types.EDIT_BOTCONFIG, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, attributeObj) => {
    console.log("idididid", attributeObj)
    commit(types.SET_BOTCONFIG_UI_FLAG, { isDeleting: true });
    try {
      await BotconfigAPI.delete(attributeObj);
      commit(types.DELETE_BOTCONFIG, attributeObj.hook_id);
    } catch (error) {
      console.log("payloadpayloadpayload123", error)
      throw new Error(error);
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isDeleting: false });
    }
  },
  addSourceSetting: async function createAttribute({ commit }, attributeObj) {
    commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: true });
    try {
      const payload = changeData(attributeObj)
      console.log("payloadpayloadpayloadCreateSetting", payload)
      var options = {}
      const response = await BotconfigAPI.addSourceSetting(payload, options);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: false });
    }
  },
  trainAll: async function createAttribute({ commit }, attributeObj) {
    commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: true });
    try {
      const payload = changeData(attributeObj)
      console.log("payloadpayloadpayloadCreateUrl", payload)
      var options = {}
      const response = await BotconfigAPI.trainAll(payload, options);
      commit(types.ADD_BOTCONFIG, response.data);
      return response.data
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isCreating: false });
    }
  },
  deleteAll: async ({ commit }, attributeObj) => {
    console.log("idididid delete all", attributeObj)
    commit(types.SET_BOTCONFIG_UI_FLAG, { isDeleting: true });
    try {
      await BotconfigAPI.deleteAll(attributeObj);
      commit(types.DELETE_BOTCONFIG, attributeObj.hook_id);
    } catch (error) {
      console.log("payloadpayloadpayload123", error)
      throw new Error(error);
    } finally {
      commit(types.SET_BOTCONFIG_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_BOTCONFIG_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_BOTCONFIG]: MutationHelpers.create,
  [types.SET_BOTCONFIG]: MutationHelpers.set,
  [types.EDIT_BOTCONFIG]: MutationHelpers.update,
  [types.DELETE_BOTCONFIG]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
